<template>
  <div>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-card class="vip-card justify-center">
      <v-row no-gutters justify="center" :class="$vuetify.breakpoint.mdAndUp ? 'mx-16 mt-16 mb-8' : 'mx-2 mt-8 mb-8'">
        <v-col cols="12" md="6" lg="10">
          <v-card class="vip-progress-card mt-8" elevation="0" v-if="isLoggedIn">
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-center mb-1 ml-2">
                    <label class="white--text font-weight-bold" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">
                      {{ $t(`label.bettingPassTitle`) }}
                      <br />
                      {{ $t(`label.bettingPassWelcome`) }} {{ memberInfo.name ? memberInfo.name.slice(0, 1).toUpperCase() : memberInfo.memberCode }}
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="1" md="1" class="text-left">
                    <!-- <v-avatar :size="$vuetify.breakpoint.xsOnly ? 50 : 64" class="vip-process-bar-icon">
                      <img src="/static/image/icon/vip-level-icon.png" />
                    </v-avatar> -->
                    <v-avatar class="vip-process-bar-icon" rounded :size="$vuetify.breakpoint.xsOnly ? '50' : '64'">
                      <img src="/static/image/icon/vip-level-no2.png" />
                      <span class="centered white--text">{{ currentLvlCode }}</span>
                    </v-avatar>
                  </v-col>
                  <v-col cols="10" md="10" lg="8">
                    <v-progress-linear :height="$vuetify.breakpoint.xsOnly ? 35 : 45" class="vip-progress-bar" :value="vipProgressPercentage">
                      <template v-slot:default="{ value }">
                        <label v-if="vipProgress.member_progress_type == 2 && $vuetify.breakpoint.lgAndDown">
                          {{ vipProgress.current_deposit_progress }} / {{ vipProgress.required_deposit_amount }}
                        </label>
                        <label v-if="vipProgress.member_progress_type == 3 && $vuetify.breakpoint.lgAndDown">
                          {{ vipProgress.current_rollover_progress }} / {{ vipProgress.required_account_rollover }}
                        </label>
                      </template>
                    </v-progress-linear>
                  </v-col>

                  <v-col cols="1" lg="2" class="pa-2 ma-auto white--text" v-if="!$vuetify.breakpoint.lgAndDown">
                    <label class="right_progress_text" v-if="vipProgress.member_progress_type == 2">({{ vipProgress.current_deposit_progress }} / {{ vipProgress.required_deposit_amount }})</label>
                    <label class="right_progress_text" v-if="vipProgress.member_progress_type == 3">({{ vipProgress.current_rollover_progress }} / {{ vipProgress.required_account_rollover }})</label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters class="px-8 pt-4 pb-4">
              <v-col class="text-center" cols="6" :class="$vuetify.breakpoint.lgAndUp ? ($vuetify.breakpoint.xlOnly ? 'pl-10' : 'pl-5') : 'pl-2'">
                <app-button class="dialog-button mr-2" :disabled="!vipProgress.has_pending_claim" :action="claimVipProgress" :title="$t(`button.claimVip`)"></app-button>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="vip-progress-card mt-8" elevation="0" v-else>
            <v-row no-gutters align="end" class="px-8 pt-4">
              <v-col>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="text-center">
                    <label class="white--text font-weight-bold" :class="$vuetify.breakpoint.xsOnly ? 'subtitle-1' : 'title'">
                      {{ $t(`label.vipLogin`) }}
                    </label>
                  </v-col>
                  <v-col cols="12" class="text-center my-8">
                    <label class="white--text font-weight-bold subtitle-1">{{ $t(`label.vipLoginBenefit`) }}</label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="6" sm="5" class="mb-6 text-center">
                    <v-btn
                      height="auto"
                      @click="this.openLoginDialog"
                      class="mr-6 pa-2 font-weight-bold "
                      :class="'embedded-login-button'"
                    >
                      {{ $t(`button.login`) }}
                    </v-btn>
                  </v-col>
                  <v-col class="text-center" sm="5" cols="6">
                    <app-button
                      :customClass="'subtitle-1 full-width font-weight-bold pa-2 theme-button embedded-register-button white--text'"
                      :action="this.openRegisterDialog"
                      :title="$t(`label.signUp`)"
                    ></app-button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="$vuetify.breakpoint.mdAndUp" :class="$vuetify.breakpoint.mdAndUp ? 'mx-16 mt-16 mb-8' : 'mx-2 mt-16 mb-8'">
        <v-card style="background: transparent !important">
          <v-card class="vip-summary-table white--text" elevation="0">
            <v-row align="center" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.level`) }}
              </v-col>
              <v-col class="text-center content" :class="currentLvlCode == reward.level ? 'selected-level' : ''" cols="1" v-for="reward in currrentVipSummary" :key="reward.level">
                <v-avatar v-if="currentLvlCode != reward.level" :size="$vuetify.breakpoint.xsOnly ? '30' : '48'" rounded>
                  <img src="/static/image/icon/vip-level-no2.png" />
                  <span class="centered">{{ reward.level }}</span>
                </v-avatar>
                <v-avatar v-else :size="$vuetify.breakpoint.xsOnly ? '30' : '48'" rounded>
                  <img src="/static/image/icon/vip-level-icon.png" />
                </v-avatar>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="vip-summary-table-content white--text" elevation="0">
            <v-row align="center" style="border-bottom: 0.5px solid white;" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.rewards`) }}
              </v-col>
              <v-col
                cols="1"
                style="display:flex;min-height:150px;vertical-align: middle;justify-content: center;"
                :class="currentLvlCode == reward.level ? 'selected-level content' : 'content'"
                v-for="(reward, index) in currrentVipSummary"
                :key="reward.level"
              >
                <v-row style="align-self: center;" no-gutters>
                  <v-col cols="12" class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img class="icon-size mb-2 mx-auto" :src="getDisplayIcon(reward)"
                               v-bind="attrs"
                               v-on="on"
                          ></v-img>
                      </template>
                      <span>{{ getFreeGiftAlt(reward) }}</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="reward.reward_type == 'COINS'" :class="prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'">
                    {{ $t(`label.free`) }}
                    <br />
                    {{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}
                    <label style="font-size: 8px">{{ predefinePrizeOnIndex.indexOf(reward.level - 1) != -1 ? '& FREE GIFT ABOVE' : '' }}</label>
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="reward.reward_type == 'BONUS'" :class="prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'">
                    {{ reward.text }}
                    <br />
                    {{ $t(`label.bonus`) }}
                  </v-col>
                  <v-col class="text-center gift-text" v-if="reward.reward_type == 'GIFT'" :class="prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'">
                    {{ $t(`label.free`) }}
                    <br />
                    {{ reward.text }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" no-gutters>
              <v-col cols="2" :class="$vuetify.breakpoint.xsOnly ? 'pl-3' : 'pl-8'">
                {{ $t(`label.requirement`) }}
              </v-col>
              <v-col cols="1" :class="currentLvlCode == reward.level ? 'selected-level px-1 py-2' : ''" class="text-center content" v-for="reward in currrentVipSummary" :key="reward.level">
                <app-button
                  :custom-class="'buttonPrimary theme-button theme--light subtitle-1 pa-1'"
                  v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level"
                  :action="claimVipProgress"
                  :title="$t(`label.claim`)"
                ></app-button>
                <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </div>
      <div v-else class="mx-2 mt-8 mb-8 vip-summary-container">
        <v-row align="center" no-gutters class="d-flex flex-nowrap">
          <v-col cols="3">
            <v-card class="vip-summary-table-mobile border-top-left-radius white--text pl-3" style="height:38px" elevation="0">
              {{ $t(`label.level`) }}
            </v-card>
          </v-col>
          <v-col cols="3" class="text-center" v-for="(reward, index) in currrentVipSummary" :key="index">
            <v-card
              class="vip-summary-table-mobile white--text"
              :class="
                currentLvlCode == reward.level
                  ? index == currrentVipSummary.length - 1
                    ? 'selected-level border-top-right-radius'
                    : 'selected-level'
                  : index == currrentVipSummary.length - 1
                  ? 'border-top-right-radius'
                  : ''
              "
              elevation="0"
            >
              <v-avatar v-if="currentLvlCode != reward.level" :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                <img src="/static/image/icon/vip-level-no.png" />
                <span class="centered">{{ reward.level }}</span>
              </v-avatar>
              <v-avatar v-else :size="$vuetify.breakpoint.xsOnly ? '30' : '48'">
                <img src="/static/image/icon/vip-level-icon.png" />
              </v-avatar>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="flex-nowrap" style="border-bottom: 0.5px solid white;" no-gutters>
          <v-col cols="3">
            <v-card style="height:83px" class="vip-summary-table-content-mobile white--text pl-3" elevation="0">
              {{ $t(`label.rewards`) }}
            </v-card>
          </v-col>
          <v-col cols="3" v-for="(reward, index) in currrentVipSummary" :key="reward.level">
            <v-card class="vip-summary-table-content-mobile  white--text" :class="currentLvlCode == reward.level ? 'selected-level content' : 'content'" elevation="0">
              <v-row no-gutters>
                <v-col cols="12" class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img class="icon-size mb-2 mx-auto" :src="getDisplayIcon(reward)"
                             v-bind="attrs"
                             v-on="on"
                      ></v-img>
                    </template>
                    <span>{{ getFreeGiftAlt(reward) }}</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'COINS'" :class="[prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'  ] ">
                  {{ $t(`label.free`) }}
                  <br />
                  {{ currencySymbolMapp(currentCurrency) }}{{ reward.text }}
                  <label style="font-size: 8px">{{ predefinePrizeOnIndex.indexOf(reward.level - 1) != -1 ? '& FREE GIFT ABOVE' : '' }}</label>
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'BONUS'" :class="prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'">
                  {{ reward.text }}%
                  <br />
                  {{ $t(`label.bonus`) }}
                </v-col>
                <v-col cols="12" class="text-center" v-if="reward.reward_type == 'GIFT'" :class="prizeToHighlight.indexOf(reward.level) != -1 ? 'bold-prize-text' : 'text-center'">
                  {{ $t(`label.free`) }}
                  <br />
                  {{ reward.text }}
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" class="flex-nowrap" no-gutters>
          <v-col cols="3">
            <v-card height="46" class="vip-summary-table-content-mobile white--text border-bottom-left-radius pl-3" elevation="0">
              {{ $t(`label.requirement`) }}
            </v-card>
          </v-col>
          <v-col cols="3" v-for="(reward, index) in currrentVipSummary" :key="index">
            <v-card
              align="center"
              class="vip-summary-table-content-mobile text-center white--text"
              height="46"
              :class="
                currentLvlCode == reward.level
                  ? index == currrentVipSummary.length - 1
                    ? 'selected-level border-bottom-right-radius'
                    : 'selected-level'
                  : index == currrentVipSummary.length - 1
                  ? 'border-bottom-right-radius'
                  : ''
              "
              elevation="0"
            >
              <app-button class="pa-1" v-if="vipProgress.has_pending_claim && currentLvlCode == reward.level" :action="claimVipProgress" :title="$t(`label.claim`)"></app-button>
              <span v-else>{{ currencySymbolMapp(currentCurrency) }}{{ reward.requirements }}</span>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters>
        <v-col cols="12" class="text-center mb-6" v-if="pagination.totalPage > 1">
          <v-pagination
            dark
            circle
            class="vip_summary_pagination"
            v-model="pagination.currentPage"
            :length="pagination.totalPage"
            @input="changePage"
            :next-icon="'arrow_forward_ios'"
            :prev-icon="'arrow_back_ios'"
            :total-visible="pagination.paginationPageLimit"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { BONUS_LIST, VIP_BONUS_APPLY, VIP_BONUS_CHECK, VIP_BONUS_RESET, VIP_CLAIM_REWARD, VIP_RESET_CLAIM, VIP_REWARDS_SUMMARY, VOUCHER_BONUS_CHECK } from '../../store/bonus.module'
import { MEMBER_GET_VIP_PROGRESS } from '../../store/member.module'
import { VipClaimType } from '../../constants/enums'
import { ROUTE_NAME } from '../../constants/route.constants'
import { locale, errorCodeHelper, uiHelper } from '@/util'
import currencySymbolMapp from 'currency-symbol-map'
import { SHARED } from '@/constants/constants'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'

export default {
  name: 'vipPage',
  data: () => ({
    predefinePrizeOnIndex: [95, 96, 97, 98, 99],
    prizeToHighlight: [9,19,29,39,49,59,69,79,89,96,97,98,99,100],
    bonusPercentage: '',
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    currrentVipSummary: [],
    searchCriteria: {
      pageNumber: 1,
      pageSize: 10,
      mobilePageSize: 10
    },
    currentCurrency: uiHelper.getCurrency(),
    currencySymbolMapp: currencySymbolMapp,
    alertShow: false,
    alertSuccess: true,
    alertMessage: ''
  }),
  components: {
    AppLoginEmbedded
  },
  computed: {
    memberInfo() {
      return this.$store.state.member.info
    },
    bonusList() {
      return this.$store.state.bonus.bonusList
    },
    isApplicable() {
      return this.$store.state.bonus.vipBonusCheckResult.data.available
    },
    applyComplete() {
      return this.$store.state.bonus.vipBonusApplyResult.complete
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipClaimComplete() {
      return this.$store.state.bonus.vipProgressClaimResult.complete
    },
    vipRewardsSummaryData() {
      return this.$store.state.bonus.vipRewardsSummary
    },
    vipClaimType() {
      switch (this.vipProgress.member_progress_type) {
        case VipClaimType.BOTH:
          return 'Rollover and Deposit'
        case VipClaimType.DEPOSIT_ONLY:
          return 'Deposit Only'
        case VipClaimType.ROLLOVER_ONLY:
          return 'Rollover Only'
      }
    },
    vipProgressPercentage() {
      if (this.vipProgress.member_progress_type == 2) {
        return (this.vipProgress.current_deposit_progress / this.vipProgress.required_deposit_amount) * 100
      } else if (this.vipProgress.member_progress_type == 3) {
        return (this.vipProgress.current_rollover_progress / this.vipProgress.required_account_rollover) * 100
      }
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    currentLvlCode() {
      return this.vipProgress.current_level_name.split(' ')[1]
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    }
  },
  watch: {
    bonusList() {
      try {
        let bonusList = _.cloneDeep(this.$store.state.bonus.bonusList)
        let currentMemberGroup = this.vipProgress.current_level_name
        if (typeof bonusList != 'undefined' && bonusList.length > 0) {
          let releaseTierBonus = bonusList.find(x => x.useReleaseTiers == 1)
          if (releaseTierBonus != null) {
            let selectedApplyBonusMemberGroup = releaseTierBonus.memberGroup.find(x => x.member_group.toLowerCase() == currentMemberGroup.toLowerCase())
            if (selectedApplyBonusMemberGroup != null) {
              this.bonusPercentage = '(' + selectedApplyBonusMemberGroup.percentage_given + '%' + ')'
            }
          }
        }
      } catch (err) {
        this.bonusPercentage = ''
      }
    },
    applyComplete() {
      if (this.$store.state.bonus.vipBonusApplyResult.success) {
        window.alert('VIP Bonus apply status: ' + this.$store.state.bonus.vipBonusApplyResult.success)
        this.$store.dispatch(`${VIP_BONUS_RESET}`)
        this.getVipApplicability()
      }
    },
    vipClaimComplete() {
      if (this.$store.state.bonus.vipProgressClaimResult.complete) {
        this.showVipClaimProgress(this.$store.state.bonus.vipProgressClaimResult)
      }
    },
    vipRewardsSummaryData() {
      this.renderPage()
      // this.changePage(1)
    },
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = ''
        this.alertMessage = ''
      }
    }
  },
  created() {
    //this.getVipApplicability()
    this.getVipProgress()
    this.getVipRewardsSummary()
    this.getAvailableBonus()
  },
  methods: {
    getAvailableBonus() {
      this.$store.dispatch(`${BONUS_LIST}`)
    },
    getVipApplicability() {
      if (this.isLoggedIn) {
        this.$store.dispatch(`${VIP_BONUS_CHECK}`)
      }
    },
    getVipProgress() {
      if (this.isLoggedIn) {
        this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
      }
    },
    getVipRewardsSummary() {
      this.$store.dispatch(VIP_REWARDS_SUMMARY)
    },
    renderPage() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.mobilePageSize)
      } else {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.pageSize)
      }
      this.searchCriteria.pageNumber = 1
      this.changePage(1)
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      if (this.$vuetify.breakpoint.xsOnly) {
        this.currrentVipSummary = this.vipRewardsSummaryData.slice(
          (this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize,
          this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize
        )
      } else {
        this.currrentVipSummary = this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
      }
    },
    applyVipBonus() {
      const selectedBonusId = this.$store.state.bonus.vipBonusCheckResult.data.bonusId
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: {
          bonusId: selectedBonusId
        }
      })
    },
    claimVipProgress() {
      if (this.isApplicable) {
        this.reminderDialog()
      } else {
        this.dispatchVipProgress()
      }
    },
    dispatchVipProgress() {
      this.$store.dispatch(VIP_CLAIM_REWARD)
    },
    showVipClaimProgress(result) {
      if (result.success) {
        this.openAlert(true, locale.getMessage(`message.vipBonusSuccess`))
      } else {
        this.openAlert(false, locale.getMessage(`message.vipBonusFailed`))
      }
      this.$store.dispatch(VIP_RESET_CLAIM)
      this.getVipProgress()
    },
    getDisplayIcon(reward) {
      let path = ''

      switch (reward.reward_type) {
        case 'COINS':
          path = this.getCoinPath()
          break
        case 'GIFT':
          path = reward.reward_gift ? reward.reward_gift.imgUrl : ''
          break
        case 'BONUS':
          path = this.getGiftPath()
          break
      }

      if (this.predefinePrizeOnIndex.indexOf(reward.level - 1) != -1) {
        path = reward.reward_gift.imgUrl
      }
      return path
    },
    getFreeGiftAlt(reward) {

      let alt = ''
      if (this.predefinePrizeOnIndex.indexOf(reward.level - 1) != -1) {
        alt = reward.reward_gift.name
      } else {
        alt = 'FREE COINS'
      }
      return alt
    },
    // hard code asset path
    getCoinPath() {
      return 'https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/deposit-bonus-coin.png'
    },
    getGiftPath() {
      return 'https://s3.ap-southeast-1.amazonaws.com/media-dev.ezgame4u.com/images/jaya9/vip/jayarank/free-bonus.png'
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    reminderDialog() {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.Confirmation`)
      dialog.message.push(locale.getMessage(`message.vipSpecialBonusRedeemReminder`))
      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        action: () => this.dispatchVipProgress()
      })
      dialog.button.push({
        title: locale.getMessage(`button.cancel`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    }
  }
}
</script>

<style lang="scss">

.embedded-login-button {
  height: 1.5rem;
  width: 120px;
  background-color: transparent !important;
  border: 1px solid #ffffff;
  color: #ffffff !important;
}

.embedded-register-button {
  height: 28px;
  border-radius: 4px;
  background: var(--v-secondary-base) !important;
  box-shadow: 0 2px 8px 0 rgb(6, 8, 15, 0.1), inset 0 0 3px 0 #ffdd8f;
  transition: box-shadow .5s ease-out .1s, background-position .3s ease-in, text-shadow .3s ease-in .1s;
  width: 120px !important;
  padding: 4px !important;
  .v-btn::before {
    background-color: transparent;
  }
}

.bold-prize-text {
  font-weight: bolder;
  font-size: 1.2em;
}
.mobile-prize-reward {
  border-bottom: 0.5px solid white;
}

.gift-text {
  width: 47px;
}
.right_progress_text {
  border-style: solid;
  border-color: #ffffff;
  border-width: 3px;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.vip-summary-container {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 100vw;

  .border-top-left-radius {
    border-top-left-radius: 10px !important;
  }

  .border-top-right-radius {
    border-top-right-radius: 10px !important;
  }

  .border-bottom-left-radius {
    border-bottom-left-radius: 10px !important;
  }

  .border-bottom-right-radius {
    border-bottom-right-radius: 10px !important;
  }
}

.vip-card {
  background-color: transparent !important;

  .vip-progress-card {
    background-color: rgb(80 255 219 / 24%);
    border-radius: 20px;

    .vip-progress-status-title {
      background-image: linear-gradient(to left, #fddd04, #fdb504);
      padding: 1px 4px;
    }
  }

  .icon-size {
    height: 2em;
    width: 41px;
  }

  .vip-process-bar-icon {
    z-index: 1;

    img {
      padding: 6px;
    }
  }

  .vip-progress-bar {
    background-color: #acacac !important;
    border: 10px solid #262626;
    border-radius: 20px;
    margin-top: 8px;

    .v-progress-linear__background {
      background-color: #acacac !important;
      border-color: #acacac !important;
      opacity: 1 !important;
    }

    .v-progress-linear__determinate {
      background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
    }
  }

  .vip-summary-table {
    background-color: #262626;
    border-radius: 20px 20px 0px 0px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-content {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px 0px 20px 20px !important;

    .content {
      padding: 12px;
    }
  }

  .vip-summary-table-mobile {
    background-color: #262626;
    border-radius: 0px;
    padding: 4px;

    .content {
      padding: 4px;
    }
  }

  .vip-summary-table-content-mobile {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px;
    padding: 8px 4px;

    .content {
      padding: 4px;
    }
  }

  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #acacac !important;
    color: rgba(0, 0, 0, 0.12) !important;
  }
}

.vip_summary_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 12px;
    }
  }

  .v-pagination__more {
    color: #fff;
  }
}

.selected-level {
  background-color: #5fad8b !important;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  position: relative;
  text-align: center;
}


.embedded-login-button {
  width: 110px !important;
}

@media (max-width: 1024px) {
  .vip-card {
    font-size: 13px;
  }
}

@media (max-width: 959px) {
  .vip-mobile-login-button {
    width: 120px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .vip-mobile-register-button {
    width: 120px !important;
  }
}

@media (max-width: 599px) {
  .vip-card {
    font-size: 12px;
    background-color: #ebebeb;

    .vip-progress-card {
      background-color: rgba(80, 255, 219, 0.24);
      border-radius: 20px;

      .vip-progress-status-title {
        background-image: linear-gradient(to left, #fddd04, #fdb504);
        padding: 1px 4px;
      }
    }


    .vip-process-bar-icon {
      z-index: 1;
    }

    .vip-progress-bar {
      background-color: #111111 !important;
      border: 8px solid #262626;
      border-radius: 20px;
      margin-top: 8px;

      .v-progress-linear__background {
        background-color: #111111 !important;
        border-color: #111111 !important;
        opacity: 1 !important;
      }

      .v-progress-linear__determinate {
        background-image: linear-gradient(to left, #fddd04, #fdb504) !important;
      }
    }

    .v-btn.dialog-button {
      width: inherit !important;
      height: auto !important;
      font-size: 12px !important;
    }
  }
}
</style>
